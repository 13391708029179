export const prompts = [
  "Write a scene where something tangible gets stolen.",
  "Write a scene where two people from very different worlds try (and maybe fail) to find common ground about something seemingly trivial.",
  "Write a chase scene without cars.",
  "Write a scene in which a character is introduced in a meaningful and memorable way. This does not have to be a main character, or the first time we meet them.",
  "Write a scene where the audience knows something that a character does not.",
  "Write a scene where someone makes an ally, if only temporarily, out of an enemy.",
  "Write a scene where everyone but the protagonist knows something important. the protagonist in this case could just be the most important person in the scene.",
  "Write a scene where someone gets unsolicited advice.",
  "Write a scene where one character convinces another to do something they wouldn't normally do. use their wants, needs, desires, Etc against them.",
  "Write an interrogation scene where nobody is a cop.",
  "Write a “high stakes poker” scene but nobody is playing cards. Capture the essence of that dynamic in a different setting.",
  "Write a boxing scene where nobody throws a punch. Capture the essence of two people fighting without a physical altercation.",
  "Write a scene where the high-status person only thinks they have the high status, and discovers that they do not.",
  "Write a scene where someone does something embarrassing and doesn't realize it, at least at first.",
  "Write a scene where someone does something embarrassing on purpose.",
  "Write a scene where someone does something against type. Something that seems out of character.",
  "Write a scene where the weather impacts what happens. The scene isn't about the weather, but the weather tries to steal the show. Make the characters deal with what they were already dealing with and now the weather.",
  "Write a scene that is completely silent. No dialogue, only action and description.",
  "Write a monologue (not a scene, yet) where a character reveals or confesses something about their past. Then, set the scene somewhere inappropriate for the confession and write it.",
  "Write a scene where someone's pride keeps them from accepting a solution to a problem. Have them justify it. Or have them refuse to justify it.",
  "Write a scene where someone is super rude and someone else has a surprising reaction to it.",
  "Write a scene where someone breaks or damages something. that something does not have to be a physical object.",
  "Write a scene where someone “bad” does something unexpectedly good.",
  "Write a scene where the power goes out but the scene continues.",
  "Write a scene where one character only tells lies.",
  "Write a scene where a character takes someone to a meaningful location.",
  "Write a scene where someone lashes out at someone who is on their side.",
  "Write a scene where a prop or object is important and meaningful to a character.",
  "Write a scene where someone confesses something that another person already knew.",
  "write a scene where a child pesters an adult with questions.",
  "Write a scene where a character gets increasingly worse news via voicemail, text, email, or other means, while still trying to do whatever they were doing.",
  "Write a scene where characters solve a dispute or make a decision via paper/rock/scissors or a similarly childish method.",
  "Write a scene where two characters make a low-stakes bet.",
  "Write a scene set in a hostile environment.",
  "Write a scene where two or more people celebrate, or lament, the end of an era.",
  "Write a scene where two or more people compete for a third person's attention, love, respect.",
  "Write a scene where two or more people compete for an object.",
  "Write a scene where the setting sun, rising sun, or night sky plays a role and informs the action or general tone.",
  "Write a scene set in a location you know very well, and use the details in the scene. Make the location count.",
  "Write a scene where someone is being obstinate and we don't know why. Or maybe they don’t know why.",
  "Write a scene where something tangible gets broken.",
  "Write a scene where someone talks to themselves. They do not have to be alone.",
  "Write a scene where someone gets something they want and immediately loses it or screws it up.",
  "Write a scene where someone gets something they want and are immediately disappointed or disinterested in it.",
  "Write a scene where nobody can say more than three words at a time.",
  "Write a scene where a character tells another they love them without actually saying I love you.",
  "Write a scene where a character meets a stranger and interacts with them.",
  "Write a scene where a character does something physical that is out of their comfort zone by choice.",
  "Write a scene where transportation problems heighten the conflict.",
  "Write a scene where someone has to break bad news to somebody else.",
  "Write an intervention scene that isn't about a substance abuse problem.",
  "Write a scene where someone is forced to confront something they fear, big or small.",
  "Write a scene where someone is faced with their biggest pet peeve while they are doing something else.",
  "Write a scene where one character is drunk, delirious, distracted, exhausted, or otherwise too mentally incapacitated to be of use to another character.",
  "Write a scene where someone’s overconfidence causes a problem.",
  "Write a scene where someone makes an accusation, big or small.",
  "Write a scene where someone vigorously defends themself over something trivial.",
  "Write a scene where one character is depending on another, and is let down.",
  "Write a scene where one character is expecting to be let down by another and is pleasantly surprised.",
  "Write a scene inspired by something you saw, heard, or otherwise experienced recently.",
  "Write a scene that takes place during “rush hour”. Remember rush hour looks very different depending on where the scene takes place.",
  "Write a scene where two people we would expect to hate each other, have a great connection .",
  "Write a scene where someone gets physically injured and insists on continuing whatever they were doing.",
  "Write a scene where a character makes a choice that is opposite what everyone, including you, would expect.",
  "Write a scene where the protagonist realizes one of their allies has lied about something or betrayed them in some way, big or small.",
  "Write a scene where something seemingly bad happens to a character but it ends up being a blessing in disguise.",
  "Write a scene where someone's goals are used against them to trick or manipulate them into doing something against their interests. Where they’re baited into a trap, so to speak.",
  "Write a scene where a character succeeds on sheer dumb luck. Then write a consequence.",
  "Write a scene where a character fails because of dumb luck or coincidence.",
  "Write a scene where someone’s physical weakness or lack of skill in a particular area causes problems.",
  "Write a scene where someone reveals a surprise ability or piece of information that helps.",
  "Write a scene where someone gets something they want or does well at something and the makes the protagonist jealous.",
  "Write three short scenes that (all together) fit on a single page that together advance the narrative or reveal character in some way.",
  "Write a scene where someone confides in someone that they usually would not have confided in. You can decide if it comes back to haunt them or not.",
  "Write a scene where someone does or says something they can't take back.",
  "Write a scene where a character is physically, geographically out of their comfort zone. Whether across the street or across the world, put them in a location they'd rather not be.",
  "Write a scene where someone is traveling and things are not going to plan.",
]
